/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Placeholder, SitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import Loading from '../../../components/Loading';
import { SECURED_PATHS } from '../constants';

import RequireLogin from '@domui-components/Auth/RequireLogin';
import { useAppConfig } from '@domui-hooks/appConfig';
import componentFactory from '@domui-sitecore/ComponentFactory';

import { useTranslation } from '@external/react-i18next';

const SitecoreHomepage: React.FC = () => {
  const { sitecoreContent, language } = useAppConfig();
  const { t } = useTranslation();

  const sitecoreContext = sitecoreContent?.sitecore?.context;

  if (sitecoreContext) {
    sitecoreContext.route = sitecoreContent?.sitecore?.route;
    sitecoreContext.itemId = sitecoreContent?.sitecore?.route?.itemId;
  }

  if (sitecoreContent?.error) {
    return <>{t('sitecore.content.unavailable', 'Content Unavailable')}</>;
  }
  const isProtected = () => {
    const pathName = window.location.pathname;
    for (let i = 0; i < SECURED_PATHS.length; i++) {
      if (pathName === `/${SECURED_PATHS[i]}`) return true;
      if (pathName === `/${SECURED_PATHS[i]}/`) return true;
      if (pathName === `/${language}/${SECURED_PATHS[i]}`) return true;
      if (pathName === `/${language}/${SECURED_PATHS[i]}/`) return true;
    }

    return false;
  };
  if (sitecoreContent?.sitecore?.context && sitecoreContent?.sitecore?.route) {
    return isProtected() ? (
      <RequireLogin>
        <SitecoreContext
          componentFactory={componentFactory}
          context={sitecoreContext}
        >
          <Placeholder
            name="jss-main"
            rendering={sitecoreContent.sitecore.route}
          />
        </SitecoreContext>
      </RequireLogin>
    ) : (
      <SitecoreContext
        componentFactory={componentFactory}
        context={sitecoreContext}
      >
        <Placeholder
          name="jss-main"
          rendering={sitecoreContent.sitecore.route}
        />
      </SitecoreContext>
    );
  }
  return <Loading />;
};

export default SitecoreHomepage;
